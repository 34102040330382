import SizeGuide from 'src/components/sections/SizeGuide'
import Seo from 'src/components/seo/Seo'

function Page() {
  return (
    <>
      <Seo title="Guia de tamanhos | Decathlon" />
      <SizeGuide />
    </>
  )
}

export default Page
